header.riseabove {
  display:flex;
  width:100%;
  height:59px;
  flex-flow:row no-wrap;
  background:url('../../assets/Waves.jpg');
  background-size:cover;
  border-bottom:1px solid $pink-border;
  position:fixed;
  z-index:2;
  top:0;
  right:0;
  left:0;
  @include respond-to(mobile) {
    height:89px;
  }
  nav {
    flex:1;
    color:white;
    height:100%;
    overflow:hidden;
    margin:0;
    padding:0;
    nav .container {
      max-width:none;
      
      .navbar-toggler {
        border:0;
        padding: 2px 6px;
        transition:all .2s ease-in-out;
        &:focus {
          box-shadow:none;
        }
        .navbar-toggler-icon {
          background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
        }
        &:hover {
          .navbar-toggler-icon {
            background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28226,38,185, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
          }
        }
      }
    }
    .container {
      padding:0;
      margin:0;
    }
    .logohome {
      flex:0 30%;
      display:flex;
      align-items:center;
      flex-flow:row wrap;
      img {
        display:inline;
        margin-right:10px;
        margin-top:-5px;
        max-width:45px;
      }
      a {
        margin:0;
        padding:0;
        align-items:center;
        color:white;
        font-family:$secularone;
        font-weight:bold;
        font-size:15px;
        display:flex;
        &:hover {
          color:rgb(216, 216, 216);
        }        
      }
    }
    @include respond-to(mobile) {
      .logohome {
        flex:1;
        img {
          max-width:75px;
        }
        a {
          font-size:35px;
          &:hover {
            color:rgb(216, 216, 216);
          }        
        }
      }
    }
    .wallet {
      // overflow:hidden;
      align-items:center;
      background:$pink-gradient;
      border-radius:10px;
      font-size:10px;
      margin-left:auto;
      margin-right:0px;
      &>div {
        display:flex;
        align-items:center;
      }
      .hide {
        display:none;
      }
      .btn-primary {
        padding:2px 10px;
        font-size: 14px;
        margin:0 auto;
        flex:1;
      }
      .wallet-inner {
        display:flex;
        align-items:center;
        border-radius:10px;
        &.hide {
          display:none;
        }
        .address {
          padding:2px 5px 2px 5px;
          margin:0 auto;
          text-align:center;
          margin-right:0px;
          @include respond-to(mobile-down) {
          font-size:12px;
          }
        }
        button.balance  {
          border:0;
          color:white;
          display:flex;
          flex:1;
          flex-flow:row nowrap;
          border-radius:10px;
          margin-right:2px;
          padding:2px 4px 2px 6px;
          background:$game-bg;
          &:hover {
            background:black;
          }
          > div {
            display:flex;
            flex:1;
            
            &.avatar {
              height:12px;
              display:flex;
              flex:0;
              > svg {
                max-height:14px;
              }
            }
          }
        }
      }
      
    }
    @include respond-to(mobile) {
      .container {
        padding:0 12px;
        margin:0;
      }
      .wallet {
        display:flex;
        flex-flow:row nowrap;
        flex:0;
        flex-basis:fit-content;
        max-width:none;
        align-items:center;
        background:$pink-gradient;
        border-radius:10px;
        margin-right:20px;
        font-size:inherit;
        &>div {
          display:flex;
          width:100%;
          align-items:center;
        }
        .hide {
          display:none;
        }
        .btn-primary {
          padding:2px 10px;
          font-size: 18px;
          flex:1;
          height:35px;
        }
        .wallet-inner {
          // flex:1;
          display:flex;
          align-items:center;
          flex-flow:row nowrap;
          border-radius:10px;
          &.hide {
            display:none;
          }
          .address {
            padding:4px 6px 4px 10px;
            line-height:24px;
            margin-right:4px;
          }
          button.balance  {
            border:0;
            color:white;
            display:flex;
            flex-flow:row nowrap;
            border-radius:10px;
            background:$game-bg;
            padding:3px 10px;
            margin-right:2px;
            &:hover {
              background:black;
            }
            > div {
              display:flex;
              flex:1;
              &.avatar {
                height:16px;
                margin-top:3px;
                margin-left:5px;
              }
            }
          }
        }
        
      }
    }
    .navtoggle {
      display:flex;
      flex-flow:row nowrap;
      
    }
  }
    
}
.offcanvas {
  background:rgba(0,0,0,.9);
  .btn-close {
    opacity:.8;
    --bs-btn-close-color: white !important;
    --bs-offcanvas-color:white !important;
    color:white !important;
    margin-bottom:2px;
    margin-right:4px;
    filter: invert(100%) sepia(3%) saturate(0%) hue-rotate(323deg) brightness(115%) contrast(100%);
    &:hover {
      opacity:1;
    }
}
  
  .offcanvas-body {
    padding:40px;
    
  }
  .offcanvas-title {
    font-family:$secularone;
    color:white;
      font-weight:bold;
      font-size:35px;
  }
  .navbar-nav {
    .contact-head a {
      padding-top:0;
    }
    a {
      color:white;
      font-family:$dmsans;
      &:hover {
        color:$text-bright-pink;
      }
    }
    
  }
}