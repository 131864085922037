.wrapper.adminwrap::after {
    opacity:.3;
}
.login {
    position:fixed;
    padding:10px 40px 40px 40px;
    background:rgb(32, 32, 32);
    border-radius:10px;
    align-items:center;
    text-align:center;
    display:flex;
    flex-flow:row wrap;
    border:1px solid black;
    margin:0 auto;
    width:30vw;
    left:0;
    right:0;
    top:30%;
    h1 {
        width:100%;
        font-size:40px;
        color:white;
        text-align:center;
        padding:20px 0 40px 0;
        margin:0;
        text-align:center;
    }
    .error-state {
        display:block;
        background:rgb(52, 52, 52);
        padding:5px;
        margin:30px 10px 0 10px;
        border-radius:10px;
        color:white;
        text-align:center;
        align-items:center;
    }
    > div {
        align-self:center;
        display:flex;
        flex:1 auto;
        width:100%;
        > div {
            margin:0 auto;
        }
    }
}
.logout {
    display:block;
    background:rgb(52, 52, 52);
    padding:5px;
    margin:30px 10px 0 10px;
    border-radius:10px;
    color:white;
    text-align:center;
    align-items:center;
    border:1px solid black;
}
.admin-container {
    margin:0 auto;
    margin-left:$sidebar-width;
    display:flex;
    padding:30px;
    align-items:center;
    margin-top:90px;
    position:relative;
    z-index:1;
    
    .admin-column {
        display:flex;
        flex:0 80%;
        margin:0 auto;
        flex-flow: column nowrap;
        max-width:700px;
        form {
            margin-top:100px;
            display:flex;
            flex-flow:row wrap;
            width:400px;
            label {
                font-size:16px;
                font-weight:bold;
                flex:10 100%;
            }
            input {
                display:block;
                flex:1;
                border-radius:4px;
                margin-bottom:10px;
                padding:5px 7px;
            }
            button {
                flex:1 100%;
            }
            
        }
    }
}
.admin .sidebar {
    padding:20px;
    form {
        display:flex;
        flex-flow:row wrap;
        label {
            flex:0 100%;
            input {
                width:100%;
            }

        }
    }
}