
.sidebar {
  width: $sidebar-width;
  position: fixed;
  top: 90px;
  bottom:0;
  left: 0;
  z-index: 50;
  height:calc(100dvh - 90px);
  overflow:hidden;
  transition:opacity .5s;
  @include respond-to(mobile-down) {
    display:none;
    height:calc(100dvh - 55px);
    top:55px;
    opacity:0;
    width: 100%;
    &.show {
      display:block;
      opacity:1;
      
    }
  }
  .sidebar-container {
    box-shadow:0 0 50px inset rgba(0,0,0,.7);
    background-color:rgba(0,0,0,.3);
    height:100%;
    display:flex;
    flex-flow:column;
    @include respond-to(mobile-down) {
      background-color:rgba(0,0,0,.8);
    }
    .sidebar-nav {
      display:relative;
      z-index:1;
      ul {
        margin:0;
        padding:0;
        display:flex;
        flex-flow:row nowrap;
        border-bottom:1px solid $dk-grey-border;
        box-shadow:0 20px 40px rgba(0,0,0,.4);
        li {
          list-style:none;
          font-weight:bold;
          
          button{
            color:white;
            padding:20px;
            display:block;
            text-decoration: none;
            background:transparent;
            border:0;
            &.inactive {
              color:$text-inactive-grey;
            }
            &:active {
              box-shadow:none;
            }
            &:hover {
              color:white;
            }
          }
          
        }
        span.closeSidebar {
          display:none;
          margin-right:15px;
          align-self:center;
          text-align:right;
          @include respond-to(mobile-down) {
            display:flex;
            // flex:1;
            margin-left:auto;
            align-self:right;
          }
          button {
            width:20px;
            height:20px;
            border:0;
            filter:invert(1);
            align-self:flex-end;
            margin-right:5px;
            background:url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e");
            background-repeat: no-repeat;
          }
        }
        span.profile {
          flex:1;
          margin-right:15px;
          align-self:center;
          text-align:right;
          @include respond-to(mobile-down) {
            display:none;
          }
          
          button {
            align-self:flex-end;
            background:0;
            border:0;
            opacity:.7;
            &:hover {
              opacity:1;
            }
          }
        }
      }
    }
    
      .chat-area {
        display:relative;
        z-index:0;
        
        padding: 20px 0 0 0;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        overscroll-behavior:contain;
        ul {
          padding:0;
          margin:0;
          li.chat-bubble {
            list-style:none;
            padding: 20px 0;
            border-radius: 4px;
            word-wrap: break-word;
            border-bottom:1px solid $dk-grey-border;
            position:relative;
            &.current-user {
              margin-left: auto;
              background-color: #a5d6a7;
            }
            
            .chat-content {
              padding:0 20px;
              display:flex;
              flex-flow:row nowrap;
              line-height:19px;
              .avatar {
                width:32px;
                height:32px;
                border-radius:100%;
                // flex:1;
                margin-right:10px;
                box-sizing: border-box;
                box-shadow:10px 10px 10px rgba(0,0,0,.4);
              }
              .cont-area {
                flex:4;
                flex-flow:row wrap;
                .username {
                  font-weight:bold;
                  font-size:18px;
                  flex:0 100%;
                  flex: 1;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  padding-right:30%;
                }
                .text {
                  font-size:14px;
                  color:#c2c2c2
                }
              }
              
              .chat-time {
                flex:1;
                display:flex;
                font-size: 12px;
                color: $text-inactive-grey;
                text-align: right;
                margin-left:auto;
                position:absolute;
                top:10px;
                right:20px;
              }
          }
        }
        
      }
      
    }
  }
  form {
    display:flex;
    width:100%;
    padding:10px 0 20px 0 ;
    border-top:1px solid #9441FF;
    
    .fcontain {
      display:flex;
      padding:0 20px;
      width:100%;
      flex-flow:row wrap;
      .err-wal {
        text-align:center;
        background:$purple-gradient;
        flex:1;
        padding:20px;
        font-size:18px;
      }
      p.text-danger {
        display:flex;
        flex:100%
      }
      .small-name {
        flex: 100%;
        font-size:12px;
        margin-bottom:3px;
        margin-left:2px;
        letter-spacing:.04px;
      }
    }
    textarea.form-control {
      flex:4;
      font-size:14px;
      line-height:17px;
      height:60px;
      border-radius:0;
      border:1px solid red;
      
    }

    .form-control:focus {
      box-shadow: none;
    }

    .btn {
      flex:1;
      border-radius: 0;
      height:60px;
      &.btn-submit {
        background: $pink-gradient;
        color: white;
        font-size:20px;
        font-weight:bold;
        text-transform:uppercase;
      }
    }

    
  }
  
}
