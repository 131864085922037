@include respond-to(mobile-down) {
  .sidebar-toggle {
    // display:none;
    
    display:flex;
    position:fixed;
    z-index:2;
    top:59px;
    right:0;
    button {
      padding:2px 10px;
      margin:0;
      background:black;
      background:$pink-gradient;
      color:white;
      border-radius:0px;
      border:0;
      font-size:12px;
      
      img {
        padding:0;
        margin-top:-2px;
      }
      &.chat {
        border-radius:10px 0 0 10px;
      }
    }
  }
}
