.wrapper.gamewrap::after {
    opacity:.3;
}
.games-container {
    margin:0 auto;
    margin-left:$sidebar-width;
    display:flex;
    padding:30px;
    align-items:center;
    margin-top:90px;
    position:relative;
    z-index:1;
    @include respond-to(mobile-down) {
        margin-top:59px;
        margin-left:0;
        padding:15px 10px;
        max-width:100%;
        display:block;
    }
    .game-column {
        display:flex;
        flex:0 80%;
        margin:0 auto;
        flex-flow: column nowrap;
        max-width:700px;
        @include respond-to(mobile-down) {
            max-width:100vw;
        }
        h1 {
            font-family:$dmsans;
            font-weight:bold;
            border-bottom:1px solid $pink-border;
            flex: 0 0 100%;
            display:flex;
            padding:0;
            
            @include respond-to(mobile-down) {
                margin:0 0 20px 25px;
            }
        }

    }
    .single-game {
        background:$game-bg;
        display:flex;
        flex:0 0 100%;
        flex-flow:column;
        margin-top:30px;
        margin-bottom:100px;
        @include respond-to(mobile-down) {
            margin-bottom:75px;
        }
        .game-header {
            display:flex;
            flex-flow:row nowrap;
            padding:30px;
            position:relative;
            @include respond-to(mobile-down) {
                padding:10px;
            }
            .details {
                flex:1;

                
                .det {
                    display:flex;
                    flex-flow:row nowrap;
                    font-family:$dmsans;
                    .title {
                        color:$text-bright-pink;
                        text-transform: uppercase;
                        font-weight:bold;
                        width:30%;
                        text-align:right;
                        margin-right:10px;
                    }
                    .value {
                        color:white;
                    }
                    &.lg-det {
                        .title {
                            font-size:18px;
                            margin-top:15px;
                        }
                        .value {
                            font-size:31px;
                            @include respond-to(mobile-down) {
                                
                            }
                        }
                    }
                    &.md-det {
                        margin-top:-6px;
                        .title {
                            font-size:13px;
                            text-transform: uppercase;
                            margin-top:6px;
                            color:$text-subdued-pink;
                        }
                        .value {
                            font-size:18px;
                        }
                    }
                    &.sm-det {
                        .title {
                            font-size:13px;
                            text-transform: uppercase;
                            color:$text-subdued-pink;
                        }
                        .value {
                            font-size:13px;
                        }
                    }
                }
                
            }
            .icon {
                flex:.2;
                margin-top:-50px;
                text-align:center;
                @include respond-to(mobile-down) {
                    margin-top:-20px;
                }
                .gameid {
                    font-size:12px;
                    margin-top:10px;
                    color:white;
                }
                img {
                    max-width:150px;
                    border-radius:10px;
                    @include respond-to(mobile-down) {
                        max-width:70px;
                    }
                }
                
            }
        }
        .game-body {
            display:flex;
            flex-flow:row wrap;
            border-bottom:1px solid $text-bright-pink;
            .slot {
                flex:0 0 50%;
                display:flex;
                flex-flow:row nowrap;
                align-items:center;
                background:$slot-open-bg;
                border-bottom:1px solid $slot-open-border;
                position:relative;
                &.half {
                    flex:0 0 50%;
                    overflow:hidden;
                }
                .slot-detail {
                    flex:3;
                    padding:12px;
                    display:flex;
                    overflow:hidden;
                    .buyer {
                        flex:1;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
                .bid {
                    flex:1;
                    padding:12px;
                    text-align:center;
                    background:$slot-open-button;
                    text-transform: uppercase;
                    cursor:pointer;
                    transition:all .1s ease-in-out;
                    
                    &:hover {
                        
                    }
                }
                &.open {
                    .bid {
                        &:hover {
                            background-color:$slot-open-button-hover;
                        }
                    }
                }
                &.pending {
                    background:$slot-pending-bg;
                    color:black;
                    border-bottom:1px solid $slot-pending-border;
                    .bid {
                        background:$slot-pending-button;
                        cursor:default;
                    }
                }
                &.locked {
                    background:$slot-locked-bg;
                    border-bottom:1px solid $slot-locked-border;
                    .bid {
                        background:$slot-locked-button;
                        content:'';
                        text-indent:-9990em;
                        position:relative;
                        cursor:default;
                        &:after {
                            position:absolute;
                            top:50%;
                            right:50%;
                            width:20px;
                            margin-right:-10px;
                            height:16px;
                            margin-top:-8px;
                            content:'';
                            background-image:url(../../assets/locked.svg);
                            background-size:20px 16px;
                        }
                    }
                }
                &.winner {
                    background:white;
                    color:$slot-open-button;
                    border-bottom:1px solid $slot-open-button;
                    .bid {
                        color:white;
                        background-color:$slot-open-button;
                        color:$slot-open-button;
                        position:relative;
                        
                        &:after {
                            content:"WINNER!!";
                            background:none;
                            width:100%;
                            text-indent:0;
                            color:white;
                            position:absolute;
                            margin-top:0;
                            left:0;
                            right:0;
                            top:50%;
                            bottom:0;
                            transform:translateY(-50%);
                            
                        }
                    }
                    
                }
                &.winnerAnim {
                    &:before {
                        content:'';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        border: 2px solid white;
                        z-index:1;
                    }
                    
                }
            }
        }

    }
}