.no-wallet {
    text-align:center;
    h3 {
        img {
            display:flex;
            margin:0 auto;
            width:40px;
            margin-bottom:10px;
        }
    }
}
.bid-error-progress {
    background:$text-bright-pink;
}
.metamask-install {
    margin:0;
    padding:0;
    li {
        display:block;
        width:100%;
        margin-top:10px;
        a {
            background:$slot-open-bg;
            display:flex;
            color:white;
            text-decoration:none;
            padding:10px;
            text-align:center;
            border-radius:4px;
            width:100%;
            &:hover {
                background:$text-subdued-pink;
            }
        }
        img {
            width:50px;
            padding:5px;
            border-radius:4px;
            background:white;
        }
        span {
            flex:1;
            align-self:center;
        }
    }
}