.past-games {
    display:relative;
    z-index:0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior:contain;
    ul {
      padding:0;
      margin:0;
      li.past-game {
        list-style:none;
        padding: 20px 0;
        border-radius: 4px;
        word-wrap: break-word;
        border-bottom:1px solid $dk-grey-border;
        position:relative;
        &.winner {
            background:url('../../assets/Waves-Small.jpg');
            background-size:cover;
        }
        &.current-user {
          margin-left: auto;
          background-color: #a5d6a7;
        }
        
        .game-content {
          padding:0 20px;
          display:flex;
          flex-flow:row nowrap;
          line-height:19px;
          align-items:center;
          .winning-number {
              display:flex;
              flex-flow:column nowrap;
              flex:1;
              height:auto;
              align-items:center;
              text-align:center;
            .wintitle {
                font-size:16px;
                line-height:14px;
                color:white;
                font-weight:bold;
                flex:1
            }
            .number {
                flex: 0 0 100%;
                padding:5px 12px;
                // background:$pink-gradient;
                // background:$blue;
                font-size:30px;
                font-weight:bold;
                margin-top:5px;
                width:70%;
                // border-radius:5px;
                // border:1px solid rgba(255,255,255,.5);
            }
          }
          .details {
              flex:3;
              padding-left:15px;
              display:flex;
              flex-flow:column;
              font-size:15px;
              h4 {
                font-size:18px;
                font-weight:bold;
              }
              .end-time {
                  color:$text-inactive-grey;
                  font-size:13px;
              }
              .det {
                  flex:1;
                  display:flex;
                  flex-flow:row nowrap;
                  .title {
                      margin-right:5px;
                      width:55apx;
                      text-align:right;
                      font-weight:bold;
                      color:$text-bright-pink;
                  }
                &.payout {
                    .detail {
                        color:white;
                        font-weight:bold;
                    }
                }
              }
          }
      }
    }
    
    }
}