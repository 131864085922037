$breakpoints: (
  // Size and UP  
  'xs' : ( min-width: 520px ),
  'mobile'  : ( min-width:  767px ),
  'tablet' : ( min-width:  992px ),
  'desktop'  : ( min-width: 1200px ),
  
  // Size and DOWN
  'xs-down' : ( max-width: 520px ),
  'mobile-down'  : ( max-width:  767px ),
  'tablet-down' : ( max-width:  992px ),
  'desktop-down'  : ( max-width: 1200px ),
  
);
 
@mixin respond-to($name) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $name) {
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints, $name))} {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}