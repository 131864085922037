.profile-modal-shroud {
    position:fixed;
    top:0;
    right:0;
    bottom:0;
    left:0;
    background:rgba(0,0,0,.7);
    z-index:2;
    
    .profile-modal {
        position:absolute;
        top:200px;
        width:50vw;
        max-width:650px;
        background:url('../../assets/Waves.jpg');
        background-size:cover;
        margin:0 auto;
        left:0;
        right:0;
        padding:20px 40px;
        position:relative;
        box-shadow:0 0 30px rgba(0,0,0,1);
        border-radius:10px;
        border-bottom:3px solid $text-subdued-pink;
        @include respond-to(mobile) {
            width:65vw;
        }
        @include respond-to(mobile-down) {
            width:90vw;
            top:30px;
            max-width:none;
            padding:20px;
        }
        button.close-modal {
            background:none;
            color: transparent;  
            text-shadow: 0 0 0 white;
            opacity:.8;
            position:absolute;
            top:10px;
            right:10px;
            &:hover {
                opacity:1;
                box-shadow:none;
            }
        }
        .profile-header {
            display:flex;
            align-items:center;
            flex-flow:row nowrap;
            margin-left:15px;
            margin-bottom:25px;
            .statbox, .header {
                // flex:1;
                margin-right:15px;
                h2 {
                    margin:0;
                    padding:0;
                }
            }
            
           
        }
        .profile-body {
            display:flex;
            align-items:center;
            flex-flow:row nowrap;
            margin-right:45px;
            margin-bottom:25px;
            @include respond-to(mobile-down) {
                flex-flow:row wrap;
                margin:0;
            }
            .profile-pic {
                flex:1;
                margin-right:15px;
                @include respond-to(mobile-down) {
                   display:none;
                }
            }
            .profile-data {
                flex:4;
            }
            .stats {
                display:flex;
                align-items:center;
                flex-flow:row nowrap;
                
                margin-bottom:15px;
                @include respond-to(mobile-down) {
                    flex-flow:row wrap;
                }
            }
            .statbox {
                background:blue;
                border-radius:10px;
                padding:5px 7px;
                align-items:center;
                display:flex;
                flex:1;
                @include respond-to(mobile-down) {
                    flex:100%;
                 }
                span {
                    flex:1;
                    margin-right:10px;
                    @include respond-to(mobile-down) {
                        // font-size:13px;
                    }
                }
                .stat {
                    line-height:10px;
                    margin:0;
                    flex:0;
                    border-radius:7px;
                    padding:7px 10px;
                    background:white;
                    color:black;
                }
            }
            .games-played {
                background-color:$slot-open-button;
                margin-right:10px;
                @include respond-to(mobile-down) {
                    margin:0;
                    margin-bottom:10px;
                }
            }
            .games-won {
                background-color: $slot-locked-bg;
            }
        }
        
        .data-box {
            background: darken($text-inactive-grey, 40%);
            padding:3px 3px 3px 10px;
            display:flex;
            flex-flow:row nowrap;
            border-radius:10px;
            align-items:center;
            label {
                font-weight:bold;
                text-transform: uppercase;
                margin-right:7px;
                flex:1;
                display:flex;
            }
            input {
                background:transparent;
                color:white;
                outline:none;
                border:0;
                border-radius:6px;
                padding:5px 7px;
                flex-grow:1;
                flex:1;
                &.editable {
                    background:white;
                    color:black;
                    
                }
            }
            .edit {
                background:white;
                display:flex;
                padding:7px 10px;
                text-align:center;
                align-items:center;
                margin-left:7px;
                flex:1;
                width:35px;

                max-width:45px;
                @include respond-to(mobile-down) {
                    max-width:35px;
                }
                img {
                    margin:0 auto;
                    align-self:center;
                    display:block;
                    margin:0;
                    padding:0;
                    width:19px;
                    height:17px;
                }
            }
        }
        .err {
            margin-top:4px;
            height:20px;
            width:100%;
            text-align:center;
            padding:0 40px;
            line-height:19px;
        }
    }
}