.home {
  position:relative;
  z-index:1;
  .container {
    padding-top:0 !important;
  }
  .logobox {
    overflow:hidden;
    margin-bottom:-70px;
    max-width:50vw;
    margin-left:auto;
    margin-right:auto;
    .hero-logo {
      // max-width:600px;
      backface-visibility: hidden;
  
    }
  }
  .opener {
    font-size:15px;
    width:70vw;
    margin:0 auto;
    line-height:22px;
    margin-bottom:30px;
    
  }
  ul {
    padding:20px 0;
    width:100%;
    font-size:14px;
    li {
      margin:0 auto;
      flex-flow:row;
      list-style:none;
      font-size: 18px;
      line-height: 36px;
      display:block;
      padding:0 20px;
    }
  }
}
@include respond-to(mobile) {
  .home {
    .container {
      padding-top:40px !important;
    }
    .logobox {
      max-width:none;
    }
    .opener {
      font-size:24px;
      line-height:32px;
      width:50vw;
    }
    ul {
      li {
        display:inline;
      }
    }
  }
}