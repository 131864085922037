/* COLORS */
$pink-border: #9441FF;
$pink-gradient:linear-gradient(151.65deg, #E226B9 17.69%, #B5489D 87.84%);
$game-bg:linear-gradient(180deg, #0A1046 0%, #430C6F 36.07%);
$dk-grey-gradient:linear-gradient(180deg, #464646 0%, #383838 99.99%, rgba(19, 43, 128, 0) 100%);
$text-inactive-grey: #acacac;
$text-grey-purple:#b5a9be;
$text-dark-grey:#4a454e;
$dk-grey-border: #4e4e4e;
$text-bright-pink:#EA1CE2;
$text-subdued-pink: #B70DB0;
$blue: #36A7D9;
$dk-blue: #132B80;

$purple-gradient: linear-gradient(332.02deg, #7137BC 21.72%, #430C6E 92.24%);

$slot-open-bg: #0A1046;
$slot-open-button:#B5489D;
$slot-open-button-hover:#da3eb8;
$slot-open-border:rgba(255, 255, 255, .2);

$slot-locked-bg:#2C84AB;
$slot-locked-button:#36A7D9;
$slot-locked-border: rgba(255, 255, 255, 0.2);

$slot-pending-bg:linear-gradient(180deg, #EEDF5A 0%, #D6C425 100%);
$slot-pending-button:rgba(255, 255, 255, 0.4);
$slot-pending-border:white;


/* Layout */
$sidebar-width: 400px;
