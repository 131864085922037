html {
  scroll-behavior: smooth;
}
body, html {
  height:100%;
  -webkit-overflow-scrolling: touch;
}
body {
  color:#e8eaed;
  letter-spacing:-.03em;
  font-family:'DM Sans', sans-serif;
  overflow:scroll !important; 
  &:after {
    content: '';
    position: fixed;
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
    z-index: -1;
    background: linear-gradient(163.5deg, #441837 3.35%, #132B80 43.32%);
  }
}

a {
  transition:all .2s ease-in-out;
}
.wrapper {
  height:100dvh;
  &::after {
    content: "";
    background-image:url("../../assets/Waves.jpg");
    background-size:cover;
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.75;
    z-index:0;
  }
}
.riseabove {
  position:relative;
  z-index:1;
}

.logo {
  font-family: 'Secular One', sans-serif;
  font-weight:400;
  font-size:48px;
  letter-spacing:-.02em;
  
}

/* BUTTONS */

.btn {
  -webkit-transition: all .15s ease-in-out;  
  -moz-transition: all .15s ease-in-out;  
  -o-transition: all .15s ease-in-out;  
  transition: all .15s ease-in-out;
  &.btn-primary {
    background: linear-gradient(95.66deg, #B5489D 2.78%, #E226B9 100%);
    border-radius: 10px;
    border:0px;
    border-color:transparent;
    padding: 10px;
    font-size: 25px;
    opacity:.8;
    font-weight:bold;
    &:active {
      background: linear-gradient(95.66deg, #B5489D 2.78%, #E226B9 100%);
      &:focus {
        box-shadow:none;
      }
    }
    &:hover {
      // background: linear-gradient(95.66deg, #953D83 2.78%, #C70AA3 100%);
      opacity:1;
      box-shadow:0 0 10px rgba(0,0,0,.2);
    }
  }
}
button {
  &:active, &:focus, &.active:focus {
    box-shadow:none !important;
  }
}